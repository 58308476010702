import fetch from 'unfetch';
import { ErrorCode } from './ErrorCode';
import { HttpResponse } from './HttpResponse';

export interface HttpClientSpec {
    readonly baseUrl: String;
    getData<T>(path: String, keyValueAuth : Record<string, string>, params?: Map<String, String>): Promise<HttpResponse<T>>;
}

export class HttpClient implements HttpClientSpec {
    constructor(public readonly baseUrl: String) {
        this.baseUrl = baseUrl;
    }

    private buildUrl(path: String, params: Map<String, String> = new Map()) {
        let reducedParams = '?';
        params.forEach((value, key) => {
            reducedParams += `${key}=${value}&`;
        });
        return `${this.baseUrl}${path}${reducedParams.slice(0, -1)}`;
    }

    async getData<T>(path: String, keyValueAuth : Record<string, string>,  params?: Map<String, String>) {
        try {
            const headerDict = {
                ...keyValueAuth,
                'Accept': 'application/json',
            }

            const result = await fetch(this.buildUrl(path, params), { headers: headerDict });

            if (result.ok) {
                const parsedResult = <T>await result.json();
                return {
                    data: parsedResult
                };
            }
            return {
                errorCode: result.statusText
            };
        } catch (err) {
            return {
                errorCode: ErrorCode.Technical
            };
        }
    }
}
