import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

type Props = {
    content: string;
    checked: boolean;
    value: any;
    onChange: any;
};

export const CheckBoxBlock = ({ content, checked, value, onChange }: Props) => {
    return (
        <Row>
            <Col lg={3} xs={1}>
                <Form.Check
                    type="checkbox"
                    checked={checked}
                    onChange={() => onChange(value)}
                />
            </Col>
            <Col lg={9} xs={11}
                 onClick={() => onChange(value)}
                 style={{textAlign : 'left'}}
            >
                <Form.Label style={{color : 'black'}}>{content}</Form.Label>
            </Col>
        </Row>
    );
};
