import React, { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import { strings } from '../../res/i18n/strings';


interface FooterProps {
    style: CSSProperties;
}

export const Footer = ({ style }: FooterProps) => {
    return (
        <Row className="footer" style={style}>
            <Col lg={6}>
                <a style={{color: 'black'}} href={'https://www.printemps.com/fr/fr/conditions-generales-vente-utilisation'}>{strings.footer.cgu}</a>
            </Col>
            <Col lg={6}>
                <a style={{color: 'black'}}  href={'https://www.printemps.com/fr/fr/protection-donnees-personnelles'}>{strings.footer.privacyPolicy}</a>
            </Col>
        </Row>
    );
};
