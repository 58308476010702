import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { BookNowButton } from './BookNowButton';
import { Event } from './../domain/events/Event';
import { useHistory } from 'react-router-dom';
import { DateInfoBlock } from '../pages/DateInfoBlock';
import { cssAbsolute, cssRelative } from './../config/cssConfig';
import { Service } from '../domain/services/Service';
import { Merchant } from '../domain/merchants/Merchant';
import { strings } from '../res/i18n/strings';
import { UILabel } from './UILabel';
import { colors } from '../res/colors';
import { filterNeutralColor } from './styles/GlobalStyle';
import { getServiceImageUrl } from '../domain/services/Service';
import { useMediaQuery } from 'react-responsive';
import { tabletMobileQuery } from '.';
import { buildUrlQueryParams } from '../utils/url';
import { getFullAddress } from '../utils/merchant';

type Props = {
    event: Event;
    service: Service;
    merchant: Merchant;
    display: number;
    color?: string;
    apiKey?: string;
};

export const CardBlock = ({ display, event, service, merchant, color, apiKey = '' }: Props) => {
    const history = useHistory();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });

    const spliceNumber = isTabletOrMobile || display === 2 ? 250 : 150;

    const redirectToDetail = () => {
        history.push(`/details/${event.id}${buildUrlQueryParams({ merchantId: merchant.id })}`, { merchant });
    };
    const flexStyle = {
        display: 'flex',
        flexDirection: 'column' as 'column'
    };
    const titleCss: React.CSSProperties = {
        color,
        fontFamily: 'urbanist-bold'
    };
    const textCss = {
        color: colors.Grey
    };
    const dateInfoCss = {
        display: 'inline-block',
        width: '7em',
        bottom: '-0.5em',
        left: '1.2em'
    };
    const cssCard = {
        borderStyle: 'none',
        padding: '0.2em',
        marginBottom: '3em'
    };

    return (
        <Card style={{ ...cssCard }}>
            <Card.Body style={{ ...flexStyle, padding: '0.2em' }}>
                <div
                    style={{
                        ...cssRelative,
                        marginBottom: '2em',
                        height: '15em',
                        borderRadius: '0.5em',
                        background: `url(${getServiceImageUrl(service)}) no-repeat center center`,
                        backgroundSize: 'cover'
                    }}
                >
                    <div style={{ ...dateInfoCss, ...cssAbsolute }}>
                        <DateInfoBlock dateStart={event.start} duration={event.duration} />
                    </div>
                </div>
                <div
                    style={{
                        ...flexStyle,
                        flex: 1,
                        textAlign: 'start',
                        marginBottom: '2em'
                    }}
                >
                    <Card.Title style={titleCss}>
                        <UILabel text={event.serviceName} />
                    </Card.Title>
                    <Card.Text style={textCss}>
                        {merchant && merchant.address ? getFullAddress(merchant.address) : strings.card.visioconference}
                    </Card.Text>
                    <Card.Text
                        style={{
                            ...flexStyle,
                            flex: 1
                        }}
                    >
                        {service && service.description
                            ? service.description.length > spliceNumber
                                ? service.description.slice(0, spliceNumber)
                                : service.description
                            : strings.card.noDescription}
                        {service && service.description && service.description.length > spliceNumber ? '...' : ''}
                    </Card.Text>
                    {service ? (
                        <span
                            style={{
                                textAlign: 'right',
                                color: 'black',
                                fontWeight: mouseOver ? 'bold' : 'normal',
                                cursor: 'pointer',
                                marginTop: '10px',
                                marginBottom: '0px',
                                marginRight: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignSelf: 'flex-end',
                                borderBottom: `2px solid ${filterNeutralColor}`
                            }}
                            onMouseOver={() => setMouseOver(true)}
                            onMouseOut={() => setMouseOver(false)}
                            onClick={redirectToDetail}
                        >
                            Voir plus
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                <BookNowButton
                    title={strings.book}
                    width={'98%'}
                    storeKey={apiKey}
                    eventId={event.id + ''}
                    disabled={!service}
                    style={{ textTransform: 'uppercase' }}
                />
            </Card.Body>
        </Card>
    );
};
