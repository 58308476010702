import React, { CSSProperties } from 'react';

interface UILabelProps {
    text: string;
    style?: CSSProperties;
}

export const UILabel = ({ text, style = {} }: UILabelProps) => {
    return <span style={{ ...style }}>{text}</span>;
};
