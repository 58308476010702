import ReactCalendar from 'react-calendar';
import moment from "moment"

type Props = {
    date: string;
    changeDate: (e: string) => void;
};
export const minusMonth = (date : string) : string => {
    let newDate = date;
    if(moment(date).add(-1, 'months').startOf('month') < moment(new Date())){
        newDate = moment(new Date()).format('yyyy-MM-DD');
    } else {
        newDate = moment(date).add(-1, 'months').startOf('month').format('yyyy-MM-DD');
    }
    return newDate;
}

export const addMonth = (date : string) : string => {
    return moment(date).add(+1, 'months').startOf('month').format('yyyy-MM-DD');
}

export const Calendar = ({ date, changeDate }: Props) => {
    const handleChange = (date : Date) : void => {
        changeDate(moment(date).format('yyyy-MM-DD'));
    };
    return (
        <div className="Calendar">
            <ReactCalendar
                locale={'fr'}
                onChange={handleChange}
                value={new Date(date)} 
                minDate = {new Date( moment().format('yyyy-MM-DD'))}    
            />
        </div>
    );
};
