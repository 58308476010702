import React from 'react';
import { DateInfoBlock } from '../pages/DateInfoBlock';
import { Event } from '../domain/events/Event';
import { Address } from '../domain/merchants/Merchant';
import { accentColor, largeMobileQuery } from './styles/GlobalStyle';
import { useMediaQuery } from 'react-responsive';
import { strings } from '../res/i18n/strings';
import { DateInfoIcon } from './DateInfoIcon';
import { colors } from '../res/colors';
import { getFullAddress } from '../utils/merchant';

interface DetailsDateInfoProps {
    event: Event;
    address: Address;
}

const getDateInfoMargin = (isMobile: boolean) => {
    return isMobile ? { marginTop: 8, marginBottom: 8 } : { padding: 6 };
};

const getDateInfoBorder = (isMobile: boolean) => {
    const borderValue = `1px solid ${accentColor}`;
    return isMobile ? { borderBottom: borderValue } : { border: borderValue, borderRadius: 8 };
};

const styles = {
    durationAddress: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center'
    },
    infoIcon: {
        marginRight: 8
    },
    infoText: {
        fontSize: '0.9rem'
    }
};

export const DetailsDateInfo = ({ event, address }: DetailsDateInfoProps) => {
    const isMobile = useMediaQuery({ query: largeMobileQuery });
    const flexDirection = isMobile ? 'column' : 'row';

    return (
        <div
            style={{
                display: 'flex',
                flexDirection,
                ...getDateInfoMargin(isMobile),
                ...getDateInfoBorder(isMobile)
            }}
        >
            <section style={{ width: isMobile ? '8em' : '7em', marginLeft: 12, marginTop: isMobile ? 8 : 0 }}>
                <DateInfoBlock dateStart={event.start} duration={event.duration} borderRadius={4} />
            </section>
            <section
                style={{
                    ...styles.durationAddress,
                    marginLeft: isMobile ? 0 : 32,
                    marginTop: isMobile ? 16 : 0,
                    marginBottom: isMobile ? 16 : 0,
                    paddingBottom: isMobile ? 8 : 0
                }}
            >
                <section>
                    <DateInfoIcon
                        source="https://medias.printemps.com/image/16/2/3196162.svg"
                        alternativeText="calendar"
                        style={{ ...styles.infoIcon, color: colors.Grey }}
                    />
                    <span style={styles.infoText}>
                        {event.duration} {strings.duration.min}
                    </span>
                </section>
                <section>
                    <DateInfoIcon
                        source="https://media-cdn.printemps.com/image/81/7/4593817.png"
                        alternativeText="location"
                        style={styles.infoIcon}
                    />
                    <span style={styles.infoText}>{getFullAddress(address)}</span>
                </section>
            </section>
        </div>
    );
};
