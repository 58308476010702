import React, { CSSProperties } from 'react';
import { Image } from 'react-bootstrap';

type Props = {
    url: string;
    height?: string;
    width?: string;
    style?: CSSProperties;
};

export const ImageBlock = ({ url, height = 'auto', width = 'auto', style = {} }: Props) => {
    return <Image src={url} style={{ height, width, ...style }} />;
};
