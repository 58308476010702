import { policies } from './policies';

const fr = {
    menu: {
        locations: 'Magasins',
        filters: 'Filtres',
        sortBy: 'Tri',
        display: 'Affichage'
    },
    footer: {
        cgu: "Conditions générales d'utilisation et de vente",
        legalMentions: 'Mentions légales',
        privacyPolicy: 'Politique de protection des données personnelles'
    },
    policy: {
        cgu: {
            title: "Conditions générales d'utilisation",
            content: 'Les conditions générales sont les suivantes : '
        },
        legalMentions: { title: 'Mentions légales' },
        privacyPolicy: { title: 'Politique de confidentialité' },
        content: { ...policies }
    },
    currentWord: {
        to: 'à',
        event: 'événement',
        events: 'événements',
        find: 'trouvé',
        findPluralMasc: 'trouvés'
    },
    details: {
        place: 'place',
        places: 'places',
        to: 'à',
        upcomming: 'Disponibilités'
    },
    loader: {
        title: 'Chargement en cours'
    },
    card: {
        noDescription: 'Pas de description',
        visioconference: 'Visioconférence'
    },
    book: 'Je reserve',
    duration: {
        min: 'min'
    },
    asideNav: {
        magasins: 'magasins',
        magasinsLink: 'https://www.printemps.com/fr/fr/magasins',
        seConnecter: 'se connecter',
        seConnecterLink: 'https://www.printemps.com/fr/fr/connexion',
        creerUnCompteLink: 'https://www.printemps.com/fr/fr/creation-compte',
        creerUnCompte: 'créer un compte'
    }
};

const i18n = {
    fr
};

export const strings = i18n.fr;
