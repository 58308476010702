
type Props = {
    height? : string
}
export const SortFilterIcon = ({height} : Props) => {

    return (
        <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="170 0 500 580" height="25px">
            <title>Plan de travail 13</title>
            <circle cx="434.24" cy="193.37" r="39.16"/>
            <circle cx="308.26" cy="358.76" r="39.16"/>
            <circle cx="560.8" cy="304.65" r="39.16"/>
            <rect x="423.33" y="104.55" width="22.4" height="346.55" rx="11.2"/>
            <rect x="297.06" y="104.55" width="22.4" height="346.55" rx="11.2"/>
            <rect x="549.6" y="101.03" width="22.4" height="346.55" rx="11.2"/>
        </svg>
    );
};

