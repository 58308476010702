import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Row, Col } from 'react-bootstrap';
import {
    Event,
    EventRepository,
    MerchantAvailabilityRepository,
    Merchant,
    GroupedEventRepository,
    Service,
    formatDate
} from '../domain';
import { CardEventBlock, MobileFilterMenu, PaginationBlock, UILoader, tabletMobileQuery } from '../ui';
import { FilterMenu } from './FilterMenu';
import { keysDefault } from './../config/defaultValueConfig';
import { strings } from '../res/i18n/strings';
import { useQueryParams } from '../hooks/useQueryParams';
import { routes } from '../navigation/routes';
import { buildUrlQueryParams } from '../utils/url';
import { useHistory } from 'react-router-dom';

const DISPLAY_GRID_3_LIMIT = 9;
const DISPLAY_GRID_2_LIMIT = 10;

export const Private = () => {
    const history = useHistory();
    const queryParams = useQueryParams();
    const queryParamLocations = queryParams.get('location');
    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });

    const [groupedEvents, setGroupedEvents] = useState<Event[][]>([]);
    const [displayedGroupedElement, setDisplayedGroupedElement] = useState<Event[][]>([]);

    const [merchants, setMerchants] = useState<Merchant[]>([]);
    const [allMerchantKeys, setAllMerchantKeys] = useState<string[]>([]);
    const [privateMerchants, setPrivateMerchants] = useState<Merchant[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [services, setServices] = useState<Service[]>([]);

    const [total, setTotal] = useState<number>(1);
    const [numberRealElement, setNumberRealElement] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(isTabletOrMobile ? DISPLAY_GRID_2_LIMIT : DISPLAY_GRID_3_LIMIT);

    const [date, setDate] = useState<string>(formatDate());
    const [display, setDisplay] = useState<number>(3);
    const [locations, setLocations] = useState<number[]>([]);
    const [categoriesSelected, setCategoriesSelected] = useState<string[]>([]);
    const [pagination, setPagination] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const displays = [2, 3];

    const changeDate = (e: string) => {
        setDate(e);
        changePagination(0);
    };
    const changeDisplay = (newDisplay: number) => {
        if (display !== newDisplay) {
            setPagination(0);
            setLimit(newDisplay % 3 === 0 ? DISPLAY_GRID_3_LIMIT : DISPLAY_GRID_2_LIMIT);
            setDisplay(newDisplay);
        }
    };
    const changeLocations = (e: number[]) => {
        setLocations(e => [...e]); // permet de rerender
        setPagination(0);
    };
    const changeSortBy = (e: string[]) => {
        setCategoriesSelected(e => [...e]); // permet de rerender
        changePagination(0);
    };
    const changePagination = (e: number) => {
        setPagination(e);
    };
    const getKeys = () => {
        let keys = [];
        keys = locations.map(l => {
            let el = merchants.find(m => m.id === l);
            return el && el.apiKey;
        });

        keys = keys.length ? keys : merchants.map(m => m.apiKey);
        return keys as string[];
    };

    useEffect(() => {
        const fetchPrivateMerchants = async () => {
            const eventRepository = new EventRepository();
            const groupedEventRepository = new GroupedEventRepository();
            const merchantAvailabilityRepository = new MerchantAvailabilityRepository();

            let cat = categoriesSelected.join(',');

            const events = await eventRepository.getEvents(allMerchantKeys, date, cat, pagination, limit);
            const limitAPI: number = events.totalReal ? events.totalReal : events.total;

            const groupedEventByDay = await groupedEventRepository.getGroupedEventsForXMonthByDay(
                allMerchantKeys,
                date,
                cat,
                pagination,
                limitAPI
            );

            const privateGroupedEventByDay = groupedEventRepository.filterPrivateGroupedEvent(
                groupedEventByDay,
                services
            );

            const privateMerchants = merchantAvailabilityRepository.filterMerchantsByPrivateServices(
                merchants,
                services,
                privateGroupedEventByDay
            );

            if (privateMerchants.length > 0) {
                setPrivateMerchants(privateMerchants);
            }
        };

        (async () => {
            await Promise.all([fetchPrivateMerchants()]);
        })();
    }, [allMerchantKeys, categoriesSelected, date, categoriesSelected, services]);

    useEffect(() => {
        const fetchServices = async () => {
            const merchantAvailabilityRepository = new MerchantAvailabilityRepository();
            const merchantAvailabilityResponse = await merchantAvailabilityRepository.getMerchantAvaibilities(
                date,
                [],
                pagination,
                limit
            );
            const currentMerchants = merchantAvailabilityResponse.merchantsAvailibility;
            setMerchants(currentMerchants);

            const allMerchantKeys = currentMerchants.map(m => m.apiKey);
            setAllMerchantKeys(allMerchantKeys);

            if (queryParamLocations) {
                setLocations(queryParamLocations.split(',').map(location => parseInt(location)));
            } else {
                setLocations(
                    !currentMerchants.find(m => m.id === keysDefault.locationIdDefault)
                        ? [currentMerchants[currentMerchants.length - 1].id]
                        : locations
                );
            }
            setServices(merchantAvailabilityResponse.paginatedResponse.items);
        };

        (async () => {
            await Promise.all([fetchServices()]);
        })();
    }, [date, limit]);

    useEffect(() => {
        const fetchGroupedEvents = async () => {
            const eventRepository = new EventRepository();
            const groupedEventRepository = new GroupedEventRepository();

            let keys = getKeys();
            let cat = categoriesSelected.join(',');

            const events = await eventRepository.getEvents(keys, date, cat, pagination, limit);
            const limitAPI: number = events.totalReal ? events.totalReal : events.total;
            const groupedEventByDay = await groupedEventRepository.getGroupedEventsForXMonthByDay(
                keys,
                date,
                cat,
                pagination,
                limitAPI
            );

            const privateGroupedEventByDay = groupedEventRepository.filterPrivateGroupedEvent(
                groupedEventByDay,
                services
            );

            setGroupedEvents(privateGroupedEventByDay);
            setNumberRealElement(privateGroupedEventByDay.length);
            setTotal(privateGroupedEventByDay.length);
            const displayedGroupedElement = groupedEventRepository.displayGroupedEventLimit(
                privateGroupedEventByDay,
                pagination,
                limit
            );
            setDisplayedGroupedElement(displayedGroupedElement);
            setOffset(events.offset);
            if (limit !== events.limit) {
                setLimit(events.limit);
            }
            !categories.length &&
                events.items &&
                setCategories(Array.from(new Set(categories.concat(events.items.map(i => i.serviceCategoryName)))));
        };
        (async () => {
            setLoading(true);
            merchants.length && services.length && (await fetchGroupedEvents());
            setLoading(false);
        })();
    }, [locations, categoriesSelected, merchants, services]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const groupedEventRepository = new GroupedEventRepository();
        if (pagination !== 0) {
            const displayedGroupedElement = groupedEventRepository.displayGroupedEventLimit(
                groupedEvents,
                pagination,
                pagination + limit
            );
            setDisplayedGroupedElement(displayedGroupedElement);
        } else {
            const displayedGroupedElement = groupedEventRepository.displayGroupedEventLimit(
                groupedEvents,
                pagination,
                limit
            );
            setDisplayedGroupedElement(displayedGroupedElement);
        }
    }, [pagination]);

    useEffect(() => {
        if (locations.length) {
            history.push(`${routes.Private}${buildUrlQueryParams({ location: locations.join(',') })}`);
        }
    }, [locations, history]);

    return (
        <div>
            <UILoader show={loading} onHide={() => setLoading(false)} />
            <Row style={{ marginBottom: '1em' }}>
                {numberRealElement > 0 && (
                    <Col xs={12} lg={isTabletOrMobile ? 12 : 5} style={{ textAlign: 'left' }}>
                        <b>{numberRealElement}</b>{' '}
                        {numberRealElement === 1
                            ? strings.currentWord.event + ' ' + strings.currentWord.find
                            : strings.currentWord.events + ' ' + strings.currentWord.findPluralMasc}
                    </Col>
                )}

                <Col xs={12} lg={isTabletOrMobile ? 12 : 9}>
                    <CardEventBlock
                        events={displayedGroupedElement}
                        display={display}
                        merchants={merchants}
                        services={services}
                    />
                </Col>
                {!isTabletOrMobile && (
                    <Col xs={12} lg={3}>
                        <FilterMenu
                            categoriesSelected={categoriesSelected}
                            date={date}
                            displayId={display}
                            locations={locations}
                            merchants={privateMerchants}
                            categories={categories}
                            displays={displays}
                            changeDate={changeDate}
                            changeDisplay={changeDisplay}
                            changeLocations={changeLocations}
                            changeSortBy={changeSortBy}
                        />
                    </Col>
                )}
            </Row>

            <Row className="ui-pagination">
                {!loading && limit < total && (
                    <PaginationBlock
                        offset={pagination}
                        total={total}
                        limit={limit}
                        changePagination={changePagination}
                    />
                )}
            </Row>
            <Row>
                {isTabletOrMobile && (
                    <MobileFilterMenu
                        categoriesSelected={categoriesSelected}
                        date={date}
                        displayId={display}
                        locations={locations}
                        merchants={privateMerchants}
                        categories={categories}
                        displays={displays}
                        changeDate={changeDate}
                        changeDisplay={changeDisplay}
                        changeLocations={changeLocations}
                        changeSortBy={changeSortBy}
                    />
                )}
            </Row>
        </div>
    );
};
