import React from 'react';
import { ArrowFilterIcon } from './ArrowFilterIcon';

type Props = {
    height? : string
}
export const LeftDateIcon = ({height = '12'} : Props) => {
    return (
        <ArrowFilterIcon color={'black'} rotation={'225'} px={'3'} height={height}/>
    )
};

