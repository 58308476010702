import { booxiMerchantKey } from '../../config/booxiApiConfig';
import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import { Event } from '../events/Event';
import moment from 'moment';
import { formatISOString } from '../dates/dateFormatter';
import { Service } from '../services/Service';
import { Tag } from '../tags/Tag';

export interface GroupedEventRepositorySpec {
    getGroupedEventsForXMonthByDay(
        merchantKeys: string[],
        date: string,
        filters: string,
        offset: number,
        limit: number
    ): any;
    displayGroupedEventLimit(listEventGrouped: Event[][], startDisplayIndex: number, limit: number): Event[][];
    getGroupedWhenOverLimitApi(merchantKeys: string[], limit: number, params: Map<string, string>): any;
    groupedEventListByDay(ListEvents: Event[], date: string): Event[][];
}

export class GroupedEventRepository implements GroupedEventRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    //To change Period : Change it in (getGroupedEventsForXMonthByDay && groupedEventListByDay) by default period == 1 month
    async getGroupedEventsForXMonthByDay(
        merchantKeys: string[],
        date: string,
        filters: string,
        offset: number,
        limit: number
    ) {
        const params: Map<string, string> = new Map<string, string>();
        let listEvents: Event[] = [];
        let allResults: any = [];

        params.set('from', formatISOString(date));
        params.set('to', formatISOString(moment(date).add(1, 'month')));
        params.set('keywords', filters);
        params.set('limit', '' + limit);
        params.set('offset', '' + offset);

        if (limit > 50) {
            allResults = await this.getGroupedWhenOverLimitApi(merchantKeys, limit, params);
        } else {
            allResults = await Promise.all(
                merchantKeys.map(key =>
                    this.datasource.remote.getData('/groupEvent', booxiMerchantKey(key ?? ''), params)
                )
            );
        }

        allResults.forEach((listEvent: any) => {
            let result: any = listEvent.data;
            listEvents = result && result.items ? [...listEvents, ...result.items] : [...listEvents];
        });

        const sortedListEvents: Event[] =
            merchantKeys.length > 1
                ? listEvents.sort(
                      (a: Event, b: Event) => +moment(a.start).format('MMDDHHMM') - +moment(b.start).format('MMDDHHMM')
                  )
                : listEvents;

        const groupedEventByDay = this.groupedEventListByDay(sortedListEvents, date);
        return groupedEventByDay as Event[][];
    }

    groupedEventListByDay(ListEvents: Event[], date: string): Event[][] {
        let tempListEvents: Event[] = [...ListEvents];
        let countDate: moment.Moment = moment(date);
        let endDate: moment.Moment = moment(date).add(1, 'month');
        let groupedEventByDay: any = [];

        while (countDate <= endDate) {
            const arrayFilter: Event[] = tempListEvents.filter(
                (elem: Event) => elem.start < formatISOString(countDate)
            );
            const index: number = arrayFilter.length;
            const setArray = Array.from(new Set(arrayFilter.map((elem: Event) => elem.serviceId))).map(serviceId =>
                arrayFilter.filter((event: Event) => event.serviceId === serviceId)
            );

            tempListEvents = tempListEvents.splice(index);
            groupedEventByDay = [...groupedEventByDay, ...setArray];

            countDate.add(1, 'day');
        }

        return groupedEventByDay;
    }

    async getGroupedWhenOverLimitApi(merchantKeys: string[], limit: number, params: Map<string, string>) {
        const numberIteration: number = Math.ceil(limit / 50);
        let concatArray: any = [];

        params.set('limit', '' + 50);

        for (let i = 1; i <= numberIteration; i++) {
            const allResults = await Promise.all(
                merchantKeys.map(key =>
                    this.datasource.remote.getData('/groupEvent', booxiMerchantKey(key ?? ''), params)
                )
            );

            concatArray = [...concatArray, ...allResults];

            params.set('offset', '' + i * 50);
        }

        return concatArray as Event[];
    }

    isEventPrivate(event: Event, services: Service[]): boolean {
        const eventService = services.find(service => event.serviceId === service.id);

        if (eventService === undefined) {
            return false;
        }

        const tags = eventService.tags.split(',');
        if (tags.find(tag => tag === Tag.private)) {
            const privateExpirationTag = tags.find(tag => tag.startsWith(Tag.AVP));

            if (privateExpirationTag !== undefined) {
                const expirationDelay: number = parseInt(privateExpirationTag.replace(Tag.AVP, ''));
                const eventStartDate = Date.parse(event.start);

                const eventPublicDate = moment(eventStartDate)
                    .subtract(expirationDelay, 'days')
                    .set({ hour: 8, minute: 0 });

                return Date.now() < eventPublicDate.valueOf();
            }

            return true;
        }

        return false;
    }

    filterPrivateGroupedEvent(listEventGrouped: Event[][], services: Service[]): Event[][] {
        const listPrivateEventGrouped: Event[][] = listEventGrouped
            .map(eventGroup => {
                return eventGroup.filter(event => this.isEventPrivate(event, services));
            })
            .reduce<Event[][]>((acc, curr) => {
                return curr.length ? [...acc, curr] : acc;
            }, []);

        return listPrivateEventGrouped;
    }

    filterPublicGroupedEvent(listEventGrouped: Event[][], services: Service[]): Event[][] {
        const listPublicEventGrouped: Event[][] = listEventGrouped
            .map(eventGroup => {
                return eventGroup.filter(event => !this.isEventPrivate(event, services));
            })
            .reduce<Event[][]>((acc, curr) => {
                return curr.length ? [...acc, curr] : acc;
            }, []);

        return listPublicEventGrouped;
    }

    displayGroupedEventLimit(listEventGrouped: Event[][], startDisplayIndex: number, limit: number): Event[][] {
        return listEventGrouped.slice(startDisplayIndex, limit);
    }
}
