import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowRightShort as ArrowRightIcon } from 'react-bootstrap-icons';
import { UILabel } from './UILabel';

type Props = {
    title: string;
    height?: string;
    width?: string;
    minWidth?: string | undefined;
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
    handleClick?: () => void;
    mouseOver?: boolean;
    disabled?: boolean;
    handleMouseOver?: (e: boolean) => void | undefined;
};

export const ButtonBlock = ({
    title,
    backgroundColor = 'white',
    color = 'black',
    height = '3em',
    width = '100%',
    borderColor = 'black',
    handleClick,
    handleMouseOver,
    mouseOver,
    minWidth,
    disabled = false
}: Props) => {
    const buttonStyles = {
        display: 'flex',
        position: 'relative' as 'relative',
        backgroundColor,
        height,
        color,
        borderColor,
        width,
        minWidth,
        fontSize: '16px',
        transitionDuration: '0.4s',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <Button
            style={{...buttonStyles, textTransform: 'inherit', fontFamily: 'urbanist-bold'}}
            onClick={handleClick}
            disabled={disabled}
            onMouseOut={() => handleMouseOver && handleMouseOver(false)}
            onMouseOver={() => handleMouseOver && handleMouseOver(true)}
        >
            <div>
                <UILabel text={title} />
            </div>
            <div style={styles.over}>{mouseOver ? <ArrowRightIcon size={24} /> : ''}</div>
        </Button>
    );
};

const styles = {
    over: {
        position: 'absolute' as 'absolute',
        right: '16px'
    }
};
