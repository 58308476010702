import React from 'react';
import { ArrowFilterIcon } from './ArrowFilterIcon';

type Props = {
    height? : string
}
export const RigthDateIcon = ({height = '12'} : Props) => {

    return (
        <ArrowFilterIcon color={'black'} rotation={'45'} px={'3'} height={height}/>
    )
};

