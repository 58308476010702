import React from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { mobileQuery, tabletMobileQuery } from './styles/GlobalStyle';
import { colors } from '../res/colors';
import { ArrowLeft as ArrowLeftIcon } from 'react-bootstrap-icons';

const BANNER_LINK = 'https://www.printemps.com';
const BANNER_TITLE = 'Événements au Printemps !';
const BANNER_SUBTITLE = 'Découvrez les ateliers et conférences près de chez vous.';

const ImageBanner = () => {
    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });
    const isMobile = useMediaQuery({ query: mobileQuery });
    const bannerImage = isMobile ? 'new_banner_mobile.png' : 'new_banner.png';

    const style: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: isMobile ? 'wrap' : 'nowrap',
        width: '100%'
    };
    const backgroundBanner: React.CSSProperties = {
        minHeight: isTabletOrMobile ? '15em' : '20em',
        maxHeight: isTabletOrMobile ? '15em' : '23em',
        width: isMobile ? '100%' : '50%',
        paddingBottom: isMobile ? 20 : 0,
        objectFit: 'cover'
    };

    return (
        <>
            <div className="encart-container active" style={style}>
                <img src={`${process.env.PUBLIC_URL}/images/${bannerImage}`} alt="banner" style={backgroundBanner} />
                <div className="encart__title text-center flex-column">
                    <a href={BANNER_LINK}>
                        <h3 className="encart-item__title mb-md-1 mb-2">{BANNER_TITLE}</h3>
                        <h6 className="encart-item__title encart-item__subtitle mb-md-1 mb-2">{BANNER_SUBTITLE}</h6>
                    </a>
                </div>
            </div>
        </>
    );
};

interface RenderBreadcrumbType {
    display: boolean;
}

const RenderBreadcrumb = ({ display }: RenderBreadcrumbType) => {
    const history = useHistory();

    const redirectToHome = () => {
        history.goBack();
    };

    const styleLink: React.CSSProperties = {
        color: 'black',
        cursor: 'pointer'
    };

    return display ? (
        <p onClick={redirectToHome} style={styleLink}>
            <>
                <ArrowLeftIcon size={20} />
                <span style={{ marginLeft: 10 }}> Retour</span>
            </>
        </p>
    ) : (
        <></>
    );
};

const BackTo = () => {
    return (
        <div className="container">
            <div style={{ display: 'flex' }}>
                {['événements /', 'details'].map((elem, index) => (
                    <RenderBreadcrumb key={elem} display={index === 1} />
                ))}
            </div>
        </div>
    );
};
const RenderLogo = () => {
    const isMobile = useMediaQuery({ query: mobileQuery });

    const logoContainer = {
        borderBottom: isMobile ? 0 : `0.5px solid ${colors.LightGrey}`,
        padding: '1.3em 0',
        marginBottom: isMobile ? 0 : '1em'
    };
    const logoOr = {
        width: isMobile ? '13rem' : '15rem',
        height: isMobile ? '1.4rem' : '1.8rem'
    };
    return (
        <div style={logoContainer}>
            <img src={`${process.env.PUBLIC_URL}/images/logo_or.svg`} alt="logo" style={logoOr} />
        </div>
    );
};

export const Header = ({ isDetails = false }) => {
    return (
        <div className="header-content">
            <RenderLogo />
            {isDetails ? <BackTo /> : ''}
            {isDetails ? '' : <ImageBanner />}
        </div>
    );
};
