import { ArrowFilterIcon } from './ArrowFilterIcon';

type Props = {
    height? : string
}

export const CloseFilterIcon = ({height = '12'} : Props) => {

    return (
        <ArrowFilterIcon color={'black'} rotation={'135'} px={'2'} height={height} />
    );
};

