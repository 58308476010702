import React, { useState, useEffect } from 'react';
import { Col, Offcanvas, Row } from 'react-bootstrap';
import { MobileFilterMenuItem } from './MobileFilterMenuItem';
import { strings } from '../../res/i18n/strings';
import { colors } from '../../res/colors';
import { MobileCalendarMenu } from './MobileCalendarMenu';
import { Filter, FilterContentRenderer } from './FilterContentRenderer';
import { Merchant } from '../../domain/merchants/Merchant';
import moment from 'moment';
import { minusMonth, addMonth } from './../../pages/Filters/Calendar'
import { LeftDateIcon } from '../../assets/icons/LeftDateIcon';
import { RigthDateIcon } from '../../assets/icons/RigthDateIcon';
import { CloseCalendarMobileIcon } from '../../assets/icons/CloseCalendarMobileIcon';
import { SortFilterIcon } from '../../assets/icons/SortFilterIcon';
import { LocationFilterIcon } from '../../assets/icons/LocationFilterIcon';

type Props = {
    displayId: number;
    displays: number[];
    date: string;
    categoriesSelected: string[];
    merchants: Merchant[];
    categories: string[];
    locations: number[];
    changeDate: (e: string) => void;
    changeDisplay: (e: number) => void;
    changeLocations: (e: number[]) => void;
    changeSortBy: (e: string[]) => void;
}


export const MobileFilterMenu = ({displayId, displays, date, merchants, locations, categoriesSelected, categories, changeDate, changeDisplay, changeLocations, changeSortBy}: Props) => {
    const [showCalendarMenu, setShowCalendarMenu] = useState(true);
    const [show, setShow] = useState(false);
    const [currentFilter, setCurrentFilter] = useState<Filter | undefined>();

    const handleClose = () => {
        setShow(false);
        setShowCalendarMenu(true);
    };
    useEffect(() => {
        handleClose();
    }, [date]);

    const handleShow = (filter: Filter) => () => {
        if (filter !== Filter.Calendar) {
            setShowCalendarMenu(false);
        }
        setCurrentFilter(filter);
        setShow(true);
    };

    return (
        <div className="mobile-menu">
            {showCalendarMenu && (
                <MobileCalendarMenu
                    date={moment(date).format('MMMM').toUpperCase()+' '+moment(date).format('yyyy')}
                    renderLeftIcon={() => <div onClick={() => {changeDate(minusMonth(date))}}><LeftDateIcon /></div>}
                    renderRightIcon={() => <div onClick={() => {changeDate(addMonth(date))}}><RigthDateIcon /></div>}
                    onClick={handleShow(Filter.Calendar)}
                />
            )}
            <Row aria-label="Mobile menu">
                <Col
                    xs={6}
                    onClick={handleShow(Filter.Locations)}
                    style={{borderRight: `1px solid ${colors.MenuSeparator}`}}
                >
                    <MobileFilterMenuItem
                        title={strings.menu.locations}
                        renderIcon={() => <LocationFilterIcon height={'0.8em'} />}
                    />
                </Col>
                <Col xs={6} onClick={handleShow(Filter.SortBy)} style={{borderRight: `1px solid ${colors.MenuSeparator}`}}>
                    <MobileFilterMenuItem
                        title={strings.menu.filters}
                        renderIcon={() => <SortFilterIcon height={'0.8em'} />}
                    />
                </Col>
            </Row>
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
                <Offcanvas.Header style={{ display: 'flex', justifyContent: 'center' }}>
                    <Offcanvas.Title>
                        <div onClick={handleClose}>
                            <CloseCalendarMobileIcon  />
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <FilterContentRenderer filter={currentFilter}
                                           categoriesSelected={categoriesSelected} date={date} displayId={displayId} locations={locations}
                                           merchants={merchants}
                                           categories={categories}
                                           displays={displays}
                                           changeDate={changeDate}
                                           changeDisplay={changeDisplay}
                                           changeLocations={changeLocations}
                                           changeSortBy={changeSortBy}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};
