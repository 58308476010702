import React, { CSSProperties } from 'react';
import { UILabel } from '../UILabel';

interface MobileFilterMenuItemProps {
    title: string;
    renderIcon: () => JSX.Element;
    style?: CSSProperties;
}

const menuItem: CSSProperties = {
        marginTop: 8,
        marginLeft: 8,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    };

export const MobileFilterMenuItem = ({ title, renderIcon, style }: MobileFilterMenuItemProps) => {
    return (
        <div className="mobile-menu-item" style={style}>
            {renderIcon()}
            <div>
                <UILabel text={title} style={menuItem} />
            </div>
        </div>
    );
};
