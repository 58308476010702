import React from 'react';
import { UILabel } from '../UILabel';

interface PolicyContent {
    title: string;
    text: string;
}

interface PolicyProps {
    title: string;
    content: PolicyContent[];
}

export const Policy = ({ title, content }: PolicyProps) => {
    return (
        <div className="page-policy">
            <UILabel text={title} style={{ fontSize: 32 }} />
            <section className="content-policy">
                {content.map((item, index) => (
                    <div key={index}>
                        <UILabel text={item.title} />
                        <section>{item.text}</section>
                    </div>
                ))}
            </section>
        </div>
    );
};
