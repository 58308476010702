export interface Service {
    id: number;
    name: string;
    profileImageUrl: string;
    description: string;
    duration: number;
    merchantId: number;
    categoryId: number;
    categoryName: string;
    tags: string;
}

export const getServiceImageUrl = (service?: Service) =>
    service && service.profileImageUrl && service.profileImageUrl !== ''
        ? service.profileImageUrl
        : 'https://medias-cache.printemps.com/image/03/6/3137036.jpg';
