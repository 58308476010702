import { strings } from '../../res/i18n/strings';
import { Calendar } from './../../pages/Filters/Calendar';
import { Location } from './../../pages/Filters/Location';
import { SortBy } from './../../pages/Filters/SortBy';
import { Display } from './../../pages/Filters/Display';
import React from 'react';
import { Merchant } from '../../domain/merchants/Merchant';
import { useMediaQuery } from 'react-responsive';
import { tabletMobileQuery } from '../styles/GlobalStyle';

export enum Filter {
    Locations,
    SortBy,
    Filters,
    Display,
    Calendar
}

type Props = {
    filter?: Filter;
    displayId: number;
    displays: number[];
    date: string;
    categoriesSelected: string[];
    merchants: Merchant[];
    categories: string[];
    locations: number[];
    changeDate: (e: string) => void;
    changeDisplay: (e: number) => void;
    changeLocations: (e: number[]) => void;
    changeSortBy: (e: string[]) => void;
};

export const FilterContentRenderer = ({
    filter,
    displayId,
    displays,
    date,
    merchants,
    locations,
    categoriesSelected,
    categories,
    changeDate,
    changeDisplay,
    changeLocations,
    changeSortBy
}: Props) => {
    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });
    const viewportStyle = isTabletOrMobile
        ? {
              maxHeight: Math.round(window.innerHeight / 4),
              overflow: 'scroll',
              padding: '0 1em'
          }
        : {};
    switch (filter) {
        case Filter.Locations:
            return (
                <div style={viewportStyle}>
                    <Location merchants={merchants} locations={locations} changeLocations={changeLocations} />
                </div>
            );
        case Filter.SortBy:
            return (
                <div style={viewportStyle}>
                    <SortBy sortByIds={categoriesSelected} changeSortBy={changeSortBy} sortByElements={categories} />
                </div>
            );

        case Filter.Filters:
            return <div style={viewportStyle}>{strings.menu.filters}</div>;
        case Filter.Display:
            return <Display displays={[1]} id={1} changeDisplay={changeDisplay} />;
        case Filter.Calendar:
            return (
                <div className="mobile-calendar-menu-content">
                    <Calendar date={date} changeDate={changeDate} />
                </div>
            );
        default:
            return null;
    }
};
