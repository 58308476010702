import React, { CSSProperties } from 'react';

const DEFAULT_INFO_ICON_DIMENSION = 16;

interface DateInfoIconProps {
    source: string;
    alternativeText: string;
    dimension?: number;
    style?: CSSProperties;
}
export const DateInfoIcon = ({
    source,
    alternativeText,
    dimension = DEFAULT_INFO_ICON_DIMENSION,
    style = {}
}: DateInfoIconProps) => {
    return <img src={source} alt={alternativeText} width={dimension} style={style} />;
};
