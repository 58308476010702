import { Form } from 'react-bootstrap';
import { Merchant } from '../../domain/merchants/Merchant';
import { CheckBoxBlock } from '../../ui';

type Props = {
    merchants: Merchant[];
    locations: number[];
    changeLocations: (e: number[]) => void;
}

export const Location = ({merchants, locations, changeLocations}: Props) => {

    const handleChange = (elementId: number) => {
        let newLocations = locations;
        const index = locations.indexOf(elementId);
        if (index > -1) {
            if (newLocations.length > 1){
                newLocations.splice(index, 1);
            }
        } else {
            newLocations.push(elementId);
        }
        changeLocations(newLocations);
    };


    return (
        <div className="Location">
            <Form>
                <Form.Group className="mb-3">
                    {(merchants.slice(0).reverse()).map(merchant => {
                        return(
                            <div key={merchant.id}>
                                <CheckBoxBlock onChange={handleChange} content={merchant.name} value={merchant.id} checked={locations.indexOf(merchant.id)>-1}/>
                            </div>
                        )
                    })}
                </Form.Group>
            </Form>
        </div>
    );
};
