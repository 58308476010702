import React, { useState, useEffect, CSSProperties } from 'react';
import { ButtonBlock } from './ButtonBlock';
import { accentColor } from './../ui/styles/GlobalStyle';

type Props = {
    title: string;
    height?: string;
    width?: string | undefined;
    minWidth?: string | undefined;
    color?: string;
    backgroundColor?: string;
    storeKey: string;
    eventId: string;
    disabled?: boolean;
    style?: CSSProperties;
};

export const BookNowButton = ({
    title,
    height,
    backgroundColor = 'black',
    color = 'white',
    storeKey,
    width = '100%',
    minWidth,
    eventId,
    disabled = false,
    style
}: Props) => {
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const bookEvent = () => {
        booxiController.open(null, {
            eventCalId: eventId,
            apiKey: storeKey,
            primaryColor: '#000000',
            secondaryColor: '#000000'
        });
    };

    useEffect(() => {
        booxiController.configure({
            apiKey: storeKey
        });
    }, [eventId, storeKey]);

    const buttonStyles = {
        backgroundColor: mouseOver ? accentColor : backgroundColor,
        color: mouseOver ? 'black' : color,
        borderColor: mouseOver ? accentColor : 'black'
    };

    return (
        <div style={style}>
            <ButtonBlock
                title={title}
                width={width}
                minWidth={minWidth ? minWidth : '160px'}
                backgroundColor={buttonStyles.backgroundColor}
                borderColor={buttonStyles.borderColor}
                color={buttonStyles.color}
                height={height}
                handleClick={bookEvent}
                mouseOver={mouseOver}
                handleMouseOver={setMouseOver}
                disabled={disabled}
            />
        </div>
    );
};
