import moment, { MomentInput } from 'moment';

const setDateToStartOfDay = (input?: MomentInput) => {
    const date = input ? moment(input) : moment();
    return date.startOf('day');
};

export const formatDate = (input?: MomentInput) => {
    return setDateToStartOfDay(input).format('YYYY-MM-DD');
};

export const formatISOString = (input?: MomentInput) => {
    return setDateToStartOfDay(input).toISOString();
};
