import { booxiMerchantKey } from '../../config/booxiApiConfig';
import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import { PaginatedResponse } from '../PaginatedResponse';
import moment from 'moment';
import { MerchantAvailibility } from './MerchantAvailibility';
import { Merchant } from '../merchants/Merchant';
import { Service } from '../services/Service';
import { formatDate } from '../dates/dateFormatter';
import { Tag } from '../tags/Tag';
import { Event } from '../events/Event';
import { GroupedEventRepository } from '../groupedEvents/GroupedEventsRepository';

export interface MerchantAvailabilityRepositorySpec {
    getMerchantAvaibilities(
        date: string,
        filters: [],
        offset: number,
        changeSortBy: number
    ): Promise<MerchantAvailibility>;
}

export class MerchantAvailabilityRepository implements MerchantAvailabilityRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getMerchantAvaibilities(date: string, filters: [], offset: number, limit: number) {
        const params = new Map<string, string>();
        params.set('from', formatDate(date));
        params.set('to', formatDate(moment(date).add(1, 'month')));
        params.set('serviceBookingMethod', 'GroupReservation');

        const eventsResponse = (await this.datasource.remote.getData(
            '/merchantAvailability',
            booxiMerchantKey('' ?? ''),
            params
        )) as any;
        const eventsPaginated = {
            limit,
            offset: offset,
            total: eventsResponse.data.services.length,
            items: eventsResponse.data.services as Service[]
        };
        const merchantAvailibilityResponse = {
            merchantsAvailibility: eventsResponse.data.merchants as Merchant[],
            paginatedResponse: eventsPaginated as PaginatedResponse<Service[]>
        };

        return (merchantAvailibilityResponse as MerchantAvailibility) || [];
    }

    hasMerchantPrivateTag(merchant: Merchant, services: Service[]): boolean {
        const eventService = services.find(service => merchant.id === service.merchantId);

        if (eventService === undefined) {
            return false;
        }

        const tags = eventService.tags.split(',');
        if (tags.find(tag => tag === Tag.private)) {
            return true;
        }

        return false;
    }

    filterMerchantsByPrivateTag(merchants: Merchant[], services: Service[]): Merchant[] {
        return merchants.filter(merchant => this.hasMerchantPrivateTag(merchant, services));
    }

    hasMerchantPrivateService(merchant: Merchant, services: Service[], listEventGrouped: Event[][]): boolean {
        const merchantServices = services.filter(service => merchant.id === service.merchantId);

        if (merchantServices.length === 0) {
            return false;
        }

        const groupedEventRepository = new GroupedEventRepository();

        return groupedEventRepository.filterPrivateGroupedEvent(listEventGrouped, merchantServices).length !== 0;
    }

    filterMerchantsByPrivateServices(
        merchants: Merchant[],
        services: Service[],
        listEventGrouped: Event[][]
    ): Merchant[] {
        return merchants.filter(merchant => this.hasMerchantPrivateService(merchant, services, listEventGrouped));
    }
}
