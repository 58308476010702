import React from 'react';
import { ArrowFilterIcon } from './ArrowFilterIcon';

type Props = {
    height? : string
}
export const OpenFilterIcon = ({height = '12'} : Props) => {

    return (
        <ArrowFilterIcon color={'black'} rotation={'315'} px={'2'} height={height}/>
    )
};

