import { CSSProperties } from 'react';

export enum TextBlockType {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5'
}

type Props = {
    text: string;
    blockType: TextBlockType;
};

export const styles: Record<TextBlockType, CSSProperties> = {
    h1: {
        display: 'block',
        fontSize: '2em',
        marginTop: '0.67em',
        marginBottom: '0.67em',
        marginLeft: 0,
        marginRight: 0,
        fontFamily: 'urbanist-bold'
    },
    h2: {
        display: 'block',
        fontSize: '1.5em',
        marginTop: '0.83em',
        marginBottom: '0.83em',
        marginLeft: 0,
        marginRight: 0,
        fontFamily: 'urbanist-bold'
    },
    h3: {
        display: 'block',
        fontSize: '1.17em',
        marginTop: '1em',
        marginBottom: '1em',
        marginLeft: 0,
        marginRight: 0,
        fontFamily: 'urbanist-bold'
    },
    h4: {
        display: 'block',
        fontSize: '1em',
        marginTop: '1.33em',
        marginBottom: '1.33em',
        marginLeft: 0,
        marginRight: 0,
        fontFamily: 'urbanist-bold'
    },
    h5: {
        display: 'block',
        fontSize: '0.83em',
        marginTop: '1.67em',
        marginBottom: '1.67em',
        marginLeft: 0,
        marginRight: 0,
        fontFamily: 'urbanist-bold'
    }
};

export const TextBlock = ({ text, blockType }: Props) => {
    return <span style={styles[blockType]}>{text}</span>;
};
