import { booxiMerchantKey } from '../../config/booxiApiConfig';
import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import { PaginatedResponse } from '../PaginatedResponse';
import { Event } from './Event';
import moment from 'moment';
import { formatISOString } from '../dates/dateFormatter';

export interface EventRepositorySpec {
    getEvents(merchantKeys: string[], date: string, filters: string, offset: number, changeSortBy: number): any;
    getEvent(merchantKey: string, id: string): Promise<Event>;
}

export class EventRepository implements EventRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getEvents(merchantKeys: string[], date: string, filters: string, offset: number, limit: number) {
        const params = new Map<string, string>();
        params.set('from', formatISOString(date));
        params.set('to', formatISOString(moment(date).add(1, 'month')));
        params.set('keywords', filters);
        params.set('limit', '' + limit);
        params.set('offset', '' + offset);

        const allResults = await Promise.all(
            merchantKeys.map(key => this.datasource.remote.getData('/groupEvent', booxiMerchantKey(key ?? ''), params))
        );
        let sumTotalPageForPage: number = 0;
        let sumTotal: number = 0;
        let concatItems: Event[] = [];
        allResults.forEach(i => {
            let result: any = i.data;
            sumTotal += result.total;
            sumTotalPageForPage = result.total > sumTotalPageForPage ? result.total : sumTotalPageForPage;
            concatItems = result.items && concatItems.concat(result.items);
        });

        return (
            ({
                limit: limit,
                offset: offset,
                total: sumTotalPageForPage,
                totalReal: sumTotal,
                items: concatItems
            } as PaginatedResponse<Event[]>) || []
        );
    }

    async getEvent(merchantKey: string, id: string) {
        const eventsResponse = await this.datasource.remote.getData('/groupEvent/' + id, booxiMerchantKey(merchantKey));
        return (eventsResponse.data as Event) || [];
    }
}
