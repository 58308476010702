import { Row, Col } from 'react-bootstrap';
import { cssCursor, cssAlignCenterContainer } from '../config/cssConfig'
import { accentColor } from './../ui/styles/GlobalStyle'


type Props = {
    offset: number;
    total: number;
    limit: number;
    changePagination: (e: number) => void;
}

export const PaginationBlock = ({offset, total = 1, limit, changePagination} : Props) => {

    const handleChange = (element : number) => {
        let newPagination = element*limit >= total ? (element-1)*limit : element*limit;
        changePagination(newPagination);
    };

    const arrayOfInt = Array.from({length:Math.ceil(total/limit)},(v,k)=>k); // tous les nombres entre 0 et total

    return (
        <Row style={{ fontSize: '16px', ...cssAlignCenterContainer }} >
            {arrayOfInt.map(element => {
                return(
                    <Col xs={1} onClick={() => handleChange(element)} key={element} style={{ marginRight: 12*((element+1+'').length-1)+''+'px', color : offset/limit === element ? accentColor : 'black', ...cssCursor }}>{element+1}</Col>
                )
            })}
            <Col xs={1} onClick={() => handleChange(offset/limit+1)} style={{...cssCursor}}> {'>'} </Col>
        </Row>
    );
};
