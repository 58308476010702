import React from 'react';
import { accentColor, filterNeutralColor } from './../../ui/styles/GlobalStyle'

type Props = {
    displayCol?: number;
    color?: string;
}

export const DisplayColIcon = ({color = 'black', displayCol = 3}: Props) => {

    const colorNonSelected = filterNeutralColor;

    const viewBoxs = ['0 0 180 180', '0 0 388 383', '0 0 525 467']

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBoxs[displayCol-1]} style={{height:'27px'}}>
            <g>
                <g>
                    { displayCol === 1 ?
                        <g>
                            <g>
                                <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M160.066 186.21H28.573c-15.59 0-28.346-12.756-28.346-28.346V28.25C.227 12.66 12.983-.095 28.573-.095h131.493c15.591 0 28.346 12.755 28.346 28.346v129.613c0 15.59-12.755 28.346-28.346 28.346z"></path>
                            </g>
                        </g> : ''
                    }
                    { displayCol === 2 ?
                        <g>
                            <g>
                                <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M160.066 186.21H28.573c-15.59 0-28.346-12.756-28.346-28.346V28.25C.227 12.66 12.983-.095 28.573-.095h131.493c15.591 0 28.346 12.755 28.346 28.346v129.613c0 15.59-12.755 28.346-28.346 28.346z"></path>
                            </g>
                            <g>
                                <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M160.012 383.022H28.517c-15.59 0-28.346-12.756-28.346-28.346V225.061c0-15.59 12.756-28.346 28.346-28.346h131.495c15.591 0 28.346 12.756 28.346 28.346v129.615c0 15.59-12.755 28.346-28.346 28.346z"></path>
                            </g>
                            <g>
                                <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M359.09 186.21H227.597c-15.59 0-28.346-12.756-28.346-28.346V28.25c0-15.591 12.756-28.346 28.346-28.346H359.09c15.591 0 28.346 12.755 28.346 28.346v129.613c0 15.59-12.755 28.346-28.346 28.346z"></path>
                            </g>
                            <g>
                                <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M359.09 383.022H227.597c-15.59 0-28.346-12.756-28.346-28.346V225.063c0-15.59 12.756-28.346 28.346-28.346H359.09c15.591 0 28.346 12.755 28.346 28.346v129.613c0 15.59-12.755 28.346-28.346 28.346z"></path>
                            </g>
                        </g> : ''
                    }
                    { displayCol === 3 ?
                        <g>
                            <g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M127.688 308.05H23.65c-12.336 0-22.428-10.092-22.428-22.428v-102.55c0-12.335 10.092-22.427 22.428-22.427h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M127.645 466.934H23.605c-12.334 0-22.427-10.092-22.427-22.428v-102.55c0-12.336 10.093-22.429 22.428-22.429h104.039c12.335 0 22.428 10.093 22.428 22.428v102.551c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M289.903 308.05H185.866c-12.336 0-22.428-10.092-22.428-22.428v-102.55c0-12.335 10.092-22.427 22.428-22.427h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M289.903 466.934H185.866c-12.336 0-22.428-10.092-22.428-22.428v-102.55c0-12.335 10.092-22.427 22.428-22.427h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M452.505 307.58H348.468c-12.336 0-22.428-10.092-22.428-22.428v-102.55c0-12.335 10.092-22.427 22.428-22.427h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M452.462 466.464H348.423c-12.335 0-22.428-10.092-22.428-22.428V341.485c0-12.335 10.093-22.428 22.428-22.428h104.04c12.334 0 22.427 10.093 22.427 22.428v102.55c0 12.337-10.093 22.429-22.428 22.429z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M126.416 148.226H22.379c-12.336 0-22.428-10.093-22.428-22.428V23.248C-.05 10.913 10.043.821 22.379.821h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.335-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M288.631 148.226H184.594c-12.336 0-22.428-10.093-22.428-22.428V23.248c0-12.335 10.092-22.427 22.428-22.427h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.335-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                                    <g>
                                        <path style={{fill: color !== accentColor ? colorNonSelected : accentColor}} d="M451.233 147.756H347.196c-12.336 0-22.428-10.092-22.428-22.428V22.778C324.768 10.443 334.86.35 347.196.35h104.037c12.335 0 22.428 10.092 22.428 22.427v102.55c0 12.336-10.093 22.428-22.428 22.428z"></path>
                                    </g>
                            </g>
                        </g> : ''
                    }
                </g>
            </g>
        </svg>
    );
};

