export const getRecord = (entry: unknown) => {
    return entry as Record<string, unknown> | undefined;
};

export const getHistoryStateEntry = <T>(key: string, subKey?: string, state?: unknown) => {
    if (!subKey) {
        return getRecord(getRecord(state)?.[key]) as T;
    }
    return getRecord(getRecord(state)?.[key])?.[subKey] as T;
};
