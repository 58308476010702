import moment from 'moment';
import 'moment/locale/fr';
import { Row, Col } from 'react-bootstrap';
import { Display } from './Filters/Display';
import { Location } from './Filters/Location';
import { SortBy } from './Filters/SortBy';
import { Calendar } from './Filters/Calendar';
import { FilterElement } from './Filters/FilterElement';
import { Merchant } from '../domain/merchants/Merchant';
import { SortFilterIcon, LocationFilterIcon, LeftDateIcon, RigthDateIcon } from '../assets';
import { minusMonth, addMonth } from './../pages/Filters/Calendar';
import { strings } from './../res/i18n/strings';

type Props = {
    displayId: number;
    displays: number[];
    date: string;
    categoriesSelected: string[] | [];
    merchants: Merchant[];
    categories: string[];
    locations: number[];
    changeDate: (e: string) => void;
    changeDisplay: (e: number) => void;
    changeLocations: (e: number[]) => void;
    changeSortBy: (e: string[]) => void;
};

export const FilterMenu = ({
    displayId,
    displays,
    date,
    merchants,
    locations,
    categoriesSelected,
    categories,
    changeDate,
    changeDisplay,
    changeLocations,
    changeSortBy
}: Props) => {
    const cssFilterElement = {
        borderRadius: '10px',
        borderColor: 'grey',
        border: '1px solid',
        paddingBottom: '1em',
        paddingTop: '1em',
        marginBottom: '1em'
    };
    return (
        <Row style={{ marginTop: '0.4em', paddingLeft: 8 }}>
            <div style={cssFilterElement}>
                <FilterElement
                    firstOpen={true}
                    renderContent={() => <Calendar date={date} changeDate={changeDate} />}
                    renderContentClose={() => (
                        <>
                            <Row>
                                <Col lg={1} sm={{ offset: 1 }}>
                                    <div
                                        onClick={() => {
                                            changeDate(minusMonth(date));
                                        }}
                                        style={{ paddingTop: '5px', cursor: 'pointer' }}
                                    >
                                        <LeftDateIcon />
                                    </div>
                                </Col>
                                <Col lg={8} style={{ fontSize: '14px' }}>
                                    {moment(date).format('MMMM').toUpperCase() + ' ' + moment(date).format('yyyy')}
                                </Col>
                                <Col lg={1}>
                                    <div
                                        onClick={() => {
                                            changeDate(addMonth(date));
                                        }}
                                        style={{ paddingTop: '5px', cursor: 'pointer' }}
                                    >
                                        <RigthDateIcon />
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                />
            </div>
            <div style={cssFilterElement}>
                <Display id={displayId} displays={displays} changeDisplay={changeDisplay} />
            </div>
            <div style={cssFilterElement}>
                <FilterElement
                    title={strings.menu.filters}
                    renderContent={() => (
                        <SortBy
                            sortByIds={categoriesSelected}
                            sortByElements={categories}
                            changeSortBy={changeSortBy}
                        />
                    )}
                    renderContentIcon={() => <SortFilterIcon height={'0.8em'} />}
                />
            </div>
            <div style={cssFilterElement}>
                <FilterElement
                    title={strings.menu.locations}
                    firstOpen={true}
                    renderContent={() => (
                        <Location merchants={merchants} locations={locations} changeLocations={changeLocations} />
                    )}
                    renderContentIcon={() => <LocationFilterIcon height={'0.8em'} />}
                />
            </div>
        </Row>
    );
};
