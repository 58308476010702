import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OpenFilterIcon, CloseFilterIcon } from '../../assets';
import {  } from './../../assets/icons/CloseFilterIcon';
import { UILabel } from '../../ui/UILabel';
import { cssCursor } from '../../config/cssConfig';

interface FilterElementProps {
    title?: string | undefined;
    renderContent: () => JSX.Element;
    renderContentClose?: () => JSX.Element;
    renderContentIcon?: () => JSX.Element;
    firstOpen?: boolean;
}

export const FilterElement = ({
    title,
    renderContent,
    renderContentClose,
    renderContentIcon,
    firstOpen = false
}: FilterElementProps) => {
    const [isOpen, setIsOpen] = useState(firstOpen);

    const buttonHandler = () => {
        setIsOpen(isOpen => !isOpen);
    };

    return (
        <div>
            <Row>
                <Col lg={9}>
                    <Row>
                        {renderContentIcon && (
                            <Col lg={2} style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
                                {renderContentIcon()}
                            </Col>
                        )}
                        {(title && (
                            <Col lg={4} style={{ textAlign: 'left', fontWeight: 'bold', padding: 0 }}>
                                <UILabel text={title.toUpperCase()} />
                            </Col>
                        )) ||
                            (renderContentClose && !isOpen && (
                                <Col sm={{ offset: 1 }} style={{ fontWeight: 'bold' }}>
                                    {renderContentClose()}
                                </Col>
                            ))}
                    </Row>
                </Col>
                <Col
                    lg={2}
                    sm={{ offset: title || renderContentClose ? 1 : 9 + 1 }}
                    onClick={buttonHandler}
                    style={{ ...cssCursor }}
                >
                    {isOpen ? <OpenFilterIcon /> : <CloseFilterIcon />}
                </Col>
            </Row>
            <Row style={{ paddingTop: isOpen ? '0.5em' : '0em' }}>{isOpen && renderContent()}</Row>
        </div>
    );
};
