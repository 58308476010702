import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { strings } from './../res/i18n/strings';

type Props = {
    dateStart: string;
    duration: number;
    width?: number | undefined;
    borderRadius?: number | undefined;
};

const styles = {
    monthHour: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column' as 'column'
    }
};

export const DateInfoBlock = ({ dateStart, duration, width, borderRadius = 10 }: Props) => {
    return (
        <Row className="DateInfoBlock" style={{ backgroundColor: 'black', color: 'white', width, borderRadius }}>
            <Col xs={5} lg={5} style={{ padding: '0' }}>
                <div
                    className="day"
                    style={{ fontWeight: 'bold', fontSize: '2.3em', display: 'flex', justifyContent: 'center' }}
                >
                    {moment(dateStart).format('DD')}
                </div>
            </Col>
            <Col xs={7} lg={7} style={styles.monthHour}>
                <Row style={{ fontSize: '13px' }}>
                    {moment(dateStart).format('MMM').toUpperCase()} {moment(dateStart).format('YYYY')}
                </Row>
                <Row style={{ fontSize: '11px' }}>
                    {moment(dateStart).format('HH:mm')} {strings.currentWord.to}{' '}
                    {moment(dateStart).add(duration, 'minutes').format('HH:mm')}
                </Row>
            </Col>
        </Row>
    );
};
