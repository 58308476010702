
type Props = {
    height? : string
}
export const LocationFilterIcon = ({height} : Props) => {

    return (
        <svg id="Calque_1" data-name="Calque 1" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Location Filter Icon</title>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 9C0 4.02944 4.02944 0 9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM9.75041 12.75V15.705C12.8821 15.3535 15.3539 12.8818 15.7054 9.75004H12.7504C12.3362 9.75004 12.0004 9.41426 12.0004 9.00004C12.0004 8.58583 12.3362 8.25004 12.7504 8.25004H15.7054C15.3539 5.1183 12.8821 2.64655 9.75041 2.29504V5.25004C9.75041 5.66426 9.41462 6.00004 9.00041 6.00004C8.5862 6.00004 8.25041 5.66426 8.25041 5.25004V2.29504C5.11867 2.64655 2.64691 5.1183 2.29541 8.25004H5.25041C5.66462 8.25004 6.00041 8.58583 6.00041 9.00004C6.00041 9.41426 5.66462 9.75004 5.25041 9.75004H2.29541C2.64691 12.8818 5.11867 15.3535 8.25041 15.705V12.75C8.25041 12.3358 8.5862 12 9.00041 12C9.41462 12 9.75041 12.3358 9.75041 12.75Z" fill="black"/>
        </svg>
    );
};

