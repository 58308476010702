import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import {
    Event,
    EventRepository,
    getServiceImageUrl,
    Address,
    ServiceIdAvailabilityRepository,
    ServiceAvailability,
    MerchantRepository
} from '../domain';
import {
    ImageBlock,
    BookNowButton,
    DescriptionBlock,
    styles,
    UILabel,
    DetailsDateInfo,
    largeMobileQuery,
    UpCommingBlockContent,
    PaginationBlock
} from '../ui';
import { routes } from '../navigation/routes';
import { strings } from '../res/i18n/strings';
import { getHistoryStateEntry } from '../utils/history';
import { useQueryParams } from '../hooks/useQueryParams';

const BOOK_NOW_BUTTON_WIDTH = '98%';
const DISPLAY_LIMIT_DESKTOP = 12; // Display in Desktop
const DISPLAY_LIMIT_MOBILE = 6;
const MARGIN_FLEX = 6;

const innerStyles = {
    bookNow: {
        marginTop: '2em',
        display: 'flex',
        justifyContent: 'center'
    }
};

export const Details = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const queryParams = useQueryParams();

    const [event, setEvent] = useState<Event | undefined>();

    const [availableDate, setAvailableDate] = useState<ServiceAvailability[]>([]);
    const [total, setTotal] = useState<number>(0);

    const [pagination, setPagination] = useState<number>(0);
    const [displayAvailableDate, setDisplayAvailableDate] = useState<ServiceAvailability[]>([]);

    const isLargeMobile = useMediaQuery({ query: largeMobileQuery });

    const apiKey = useRef(getHistoryStateEntry<string>('merchant', 'apiKey', history.location.state));
    const address = useRef(getHistoryStateEntry<Address>('merchant', 'address', history.location.state));

    const display = isLargeMobile ? DISPLAY_LIMIT_MOBILE : DISPLAY_LIMIT_DESKTOP;

    const changePagination = (e: number) => {
        setPagination(e);
    };

    const getAndSetEvent = async (apiKey: string, id: string) => {
        const eventRepository = new EventRepository();

        const event = await eventRepository.getEvent(apiKey, id);
        setEvent(event);
    };

    useEffect(() => {
        // TODO: externalize in a custom hook
        const fetchEvent = async () => {
            if (!apiKey.current) {
                const queryParamMerchantId = queryParams.get('merchantId');

                if (queryParamMerchantId) {
                    const merchantRepository = new MerchantRepository();
                    const { items: merchantList } = await merchantRepository.getMerchantList();

                    const selectedMerchant = merchantList.find(
                        merchant => merchant.id.toString() === queryParamMerchantId
                    );

                    if (!selectedMerchant) {
                        history.push(routes.Landing);
                    } else {
                        address.current = selectedMerchant.address;
                        apiKey.current = selectedMerchant.apiKey;
                        await getAndSetEvent(selectedMerchant.apiKey, id);
                    }
                } else {
                    history.push(routes.Landing);
                }
            } else {
                await getAndSetEvent(apiKey.current, id);
            }
        };

        (async () => {
            await fetchEvent();
        })();
    }, [apiKey, history, id, queryParams]);

    useEffect(() => {
        const fetchEvents = async () => {
            const serviceIdAvailabilityRepository = new ServiceIdAvailabilityRepository();

            const date = event && event.start ? moment(event.start).format('YYYY-MM-DD') : '';
            const serviceId = event && event.serviceId ? event.serviceId : null;

            const events = await serviceIdAvailabilityRepository.getAvailability(apiKey.current, serviceId, date);

            if (events && events.availableDate.length > 0) {
                setAvailableDate(events.availableDate);
                setTotal(events.total);
                const displayedAvailableService = serviceIdAvailabilityRepository.displayServiceAvailableLimit(
                    events.availableDate,
                    pagination,
                    display
                );
                setDisplayAvailableDate(displayedAvailableService);
            }
        };
        (async () => {
            await fetchEvents();
        })();
    }, [event]);

    useEffect(() => {
        const serviceIdAvailabilityRepository = new ServiceIdAvailabilityRepository();
        if (pagination !== 0) {
            const displayedAvailableService = serviceIdAvailabilityRepository.displayServiceAvailableLimit(
                availableDate,
                pagination,
                pagination + display
            );
            setDisplayAvailableDate(displayedAvailableService);
        } else if (pagination === 0 && availableDate && availableDate.length !== 0) {
            const displayedAvailableService = serviceIdAvailabilityRepository.displayServiceAvailableLimit(
                availableDate,
                pagination,
                display
            );
            setDisplayAvailableDate(displayedAvailableService);
        }
    }, [pagination, availableDate, display]);

    return (
        <div className="Details">
            {event ? (
                <div>
                    <Row>
                        <Col xs={12} lg={3} style={{ order: isLargeMobile ? 1 : -1 }}>
                            <ImageBlock
                                url={getServiceImageUrl(event.service)}
                                style={{ borderRadius: 8, width: '100%' }}
                            />
                            {!isLargeMobile && (
                                <BookNowButton
                                    title={strings.book}
                                    width={BOOK_NOW_BUTTON_WIDTH}
                                    storeKey={apiKey.current}
                                    eventId={event.id + ''}
                                    disabled={!event.service}
                                    style={{ ...innerStyles.bookNow, textTransform: 'uppercase' }}
                                />
                            )}
                        </Col>
                        <Col xs={12} lg={9} style={{ textAlign: 'left' }}>
                            <DetailsDateInfo event={event} address={address.current} />
                            {isLargeMobile && (
                                <BookNowButton
                                    title={strings.book}
                                    width={BOOK_NOW_BUTTON_WIDTH}
                                    storeKey={apiKey.current}
                                    eventId={event.id + ''}
                                    disabled={!event.service}
                                    style={{ ...innerStyles.bookNow, textTransform: 'uppercase' }}
                                />
                            )}
                            <UILabel text={event.serviceName} style={{ ...styles.h1, marginTop: '1.2em' }} />
                            <div style={{ marginBottom: isLargeMobile ? '1.2em' : '' }}>
                                <DescriptionBlock content={event.service?.description ?? ''} />
                            </div>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            borderBottom: '5px solid black',
                            marginBottom: '2.2em',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Col xs={6} lg={6} style={{ padding: 0 }}>
                            <UILabel
                                text={strings.details.upcomming}
                                style={{
                                    ...styles.h3,
                                    marginTop: '1.2em',
                                    textTransform: 'uppercase',
                                    marginBottom: '0px',
                                    marginLeft: '0px',
                                    textAlign: 'left'
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <div className="upcommingBlock">
                            <div className="uppcommingblockContent" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {displayAvailableDate
                                    ? displayAvailableDate.map((elem: any, i: number) => (
                                          <UpCommingBlockContent
                                              key={elem.groupEventId}
                                              placesLeft={elem.placesLeft}
                                              date={moment(elem.start).format('DD MMM YYYY').toUpperCase()}
                                              endHour={moment(elem.end).format('HH:mm')}
                                              startHour={moment(elem.start).format('HH:mm')}
                                              styles={
                                                  (i + 1) % MARGIN_FLEX === 0
                                                      ? { marginRight: '0' }
                                                      : { marginRight: '2%' }
                                              }
                                          />
                                      ))
                                    : ''}
                            </div>
                        </div>
                    </Row>
                    <Row className="ui-pagination">
                        {DISPLAY_LIMIT_DESKTOP < total && (
                            <PaginationBlock
                                offset={pagination}
                                total={total}
                                limit={display}
                                changePagination={changePagination}
                            />
                        )}
                    </Row>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
