import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { routes } from './navigation/routes';
import { Landing } from './pages/Landing';
import { Col, Container, Row } from 'react-bootstrap';
import { Header } from './ui/Header';
import { strings } from './res/i18n/strings';
import { Policy } from './ui/policy/Policy';
import { Footer } from './ui/footer/Footer';
import { useMediaQuery } from 'react-responsive';
import { tabletMobileQuery } from './ui/styles/GlobalStyle';
import { dimensions } from './res/dimens';
import { Details } from './pages/Details';
import { Private } from './pages/Private';

function App() {
    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });
    const footerStyle = isTabletOrMobile ? { marginBottom: dimensions.MenuHeight + 16 } : {};

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path={routes.Cgu}>
                        <Header />
                        <Container>
                            <Policy title={strings.policy.cgu.title} content={strings.policy.content.cgu} />
                        </Container>
                    </Route>
                    <Route path={routes.LegalMentions}>
                        <Header />
                        <Container>
                            <Policy
                                title={strings.policy.legalMentions.title}
                                content={strings.policy.content.legalMentions}
                            />
                        </Container>
                    </Route>
                    <Route path={routes.PrivacyPolicy}>
                        <Header />
                        <Container>
                            <Policy
                                title={strings.policy.privacyPolicy.title}
                                content={strings.policy.content.privacyPolicy}
                            />
                        </Container>
                    </Route>
                    <Route path={routes.Details}>
                        <Header isDetails={true} />
                        <Container>
                            <Details />
                        </Container>
                    </Route>
                    <Route path={routes.Private}>
                        <Header />
                        <Container>
                            <Private />
                        </Container>
                    </Route>
                    <Route path={routes.Landing}>
                        <Header />
                        <Container>
                            <Landing />
                        </Container>
                    </Route>
                </Switch>
                <Row>
                    <Col xs={12} lg={12}>
                        <Footer style={footerStyle} />
                    </Col>
                </Row>
            </Router>
        </div>
    );
}

export default App;
