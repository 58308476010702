import {  Form } from 'react-bootstrap';
import { CheckBoxBlock } from '../../ui';

type Props = {
    sortByIds: string[];
    sortByElements: string[];
    changeSortBy: (e: string[]) => void;
}

export const SortBy = ({sortByIds, sortByElements, changeSortBy}: Props) => {

    const handleChange = (elementId: string) => {
        let newElements = sortByIds;
        const index = newElements.indexOf(elementId);
        if (index > -1) {
            newElements.splice(index, 1);
        } else {
            newElements.push(elementId);
        }
        changeSortBy(newElements);
    };
    return (
        <div className="SortBy">
            <Form>
                <Form.Group className="mb-3">
                    {sortByElements && sortByElements.map(el => {
                        return(
                            <div key={el}>
                                <CheckBoxBlock onChange={handleChange} content={el} value={el} checked={sortByIds.indexOf(el)>-1}/>
                            </div>
                        )
                    })}
                </Form.Group>
            </Form>
        </div>
    );
};

