import React from 'react';
import { Row } from 'react-bootstrap';
import { Event } from './../domain/events/Event';
import { CardBlock } from '../ui/CardBlock';
import { Merchant } from '../domain/merchants/Merchant';
import { Service } from '../domain/services/Service';

type Props = {
    events: Event[][];
    merchants: Merchant[];
    services: Service[];
    display: number;
};

export const CardEventBlock = ({ events, display, merchants, services }: Props) => {
    return (
        <Row xs={1} lg={display}>
            {events &&
                events.map(event => {
                    let service = services.find(service => event[0].serviceId === service.id) as Service;
                    let merchant = merchants.find(
                        merchant => service && merchant.id === service.merchantId
                    ) as Merchant;

                    return (
                        <CardBlock
                            event={event[0]}
                            service={service}
                            merchant={merchant}
                            key={event[0].id}
                            apiKey={merchant && merchant.apiKey}
                            display={display}
                        />
                    );
                })}
        </Row>
    );
};
