
type Props = {
    content: string;
    fontWeight?: any;
    color?: string;
    onClick?: any;
};

export const ColBlock = ({ content, fontWeight = 'normal',  color = 'green', onClick }: Props) => {
    return (
        <div onClick={onClick} style={{fontWeight: fontWeight}}>
            {content}
        </div>
    );
};
