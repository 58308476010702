import { ArrowFilterIcon } from './ArrowFilterIcon';

type Props = {
    height? : string
}

export const CloseCalendarMobileIcon = ({height = '15'} : Props) => {

    return (
        <ArrowFilterIcon color={'grey'} rotation={'135'} px={'2'} height={height} />
    );
};

