
interface MobileCalendarMenuProps {
    date: string;
    renderLeftIcon: () => JSX.Element;
    renderRightIcon: () => JSX.Element;
    onClick: () => void;
}

export const MobileCalendarMenu = ({ date, renderLeftIcon, renderRightIcon, onClick }: MobileCalendarMenuProps) => {
    return (
        <div className="mobile-calendar-menu">
            {renderLeftIcon()}
            <div style={{fontWeight : 'bold'}} onClick={onClick}>{date}</div>
            {renderRightIcon()}
        </div>
    );
};
