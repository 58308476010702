import  { CSSProperties } from 'react';
import { useMediaQuery } from 'react-responsive';
import { strings } from '../res/i18n/strings';
import { tabletMobileQuery } from '../ui';

type Props = {
    startHour  : string,
    date       : string,
    endHour    : string,
    placesLeft : number,
    styles?     : CSSProperties
} 

const DISPLAY_FLEX = 6;

export const UpCommingBlockContent = ({startHour, date, endHour, placesLeft, styles = {}}: Props) => { 

    const isTabletOrMobile = useMediaQuery({ query: tabletMobileQuery });
    const widthValue = (100-(2*DISPLAY_FLEX - 2))/DISPLAY_FLEX;
    
    const desktopStyle : CSSProperties = {
        flex: `1 1 ${widthValue}%`, 
        width:`${widthValue}%`,
        maxWidth:`${widthValue}%`,
        display:'flex', 
        flexWrap:'wrap', 
        marginBottom:'2.2em',
        ...styles,
    }

    const mobileStyle : CSSProperties = {
        flex: '1 1 100%', 
        width:'100%',
        maxWidth:'100%',
        display:'flex', 
        flexWrap:'wrap', 
        marginBottom:'2.2em',
        marginRight : 0,
    }
    
    return(
        <>
            <div className="blockContent " style={isTabletOrMobile?  mobileStyle : desktopStyle} >
                <div className="dateInfo" style={{ width:'70%',maxWidth: '70%',textAlign:'center', padding:'12px 16px',marginTop:'0', border:'0.5px solid black', borderRadius:'8px 0px 0px 8px'}}>
                    <span style={{display:'block', fontWeight: 'bold',fontSize:'12px', position:'relative', top:'5px'}}>{startHour}h {strings.details.to} {endHour}h</span>
                    <span style={{fontSize : '12px',color:'gray'}}>{date}</span>
                </div>
                <div className="dateInfo" style={{backgroundColor:"black", width:'30%', maxWidth:'30%', padding:'12px 8px',textAlign:'center', borderRadius:'0px 8px 8px 0px'}}>
                    <span style={{display:'block', color:'white', fontWeight:'bold', fontSize:'12px'}}>{placesLeft}</span>
                    <span style={{display:'block', color:'white', fontWeight:'bold', fontSize:'12px', border:'0.5px solid black', textTransform:'capitalize',position:'relative', top:'-3px'}}>{placesLeft>0? strings.details.places : strings.details.place}</span>
                </div>
            </div>     
        </>
    )
}