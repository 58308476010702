import { Merchant } from './Merchant';
import { PaginatedResponse } from '../PaginatedResponse';
import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';

export interface MerchantRepositorySpec {
    getMerchantList(): Promise<PaginatedResponse<Merchant[]>>;
}

export class MerchantRepository implements MerchantRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getMerchantList() {
        try {
            const params = new Map<string, string>();
            params.set('limit', '30');

            const merchantListResponse = await this.datasource.remote.getData('/merchant/list', {}, params);
            return (merchantListResponse.data as PaginatedResponse<Merchant[]>) || [];
        } catch (err) {
            throw err;
        }
    }
}
