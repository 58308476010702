export const cssCursor = {
    cursor: 'pointer'
}
export const cssRelative = {
    position: 'relative' as 'relative'
}
export const cssAbsolute = {
    position: 'absolute' as 'absolute'
}
export const cssAlignCenterContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}