import { HttpClient, HttpClientSpec } from '../network/HttpClient';

export interface ApiDatasourceSpec {
    readonly remote: HttpClientSpec;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? '';

export class ApiDatasource implements ApiDatasourceSpec {
    constructor(public readonly remote: HttpClientSpec = new HttpClient(API_BASE_URL)) {}
}
