import React from 'react';
import { ColBlock } from "./ColBlock";

type Props = {
    content: string;
    color?: string;
}

export const DescriptionBlock = ({ content, color = 'green' }: Props) => {
    return (
        <ColBlock content={content} />
    );
};
