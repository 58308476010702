import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { strings } from '../res/i18n/strings';
import { UILabel } from './UILabel';

interface UILoaderProps {
    show: boolean;
    onHide: () => void;
}

export const UILoader = ({ show, onHide }: UILoaderProps) => {
    return (
        <Modal
            className="ui-loader"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
            show={show}
            onHide={onHide}
        >
            <div className="ui-loader-content">
                <div>
                    <UILabel text={strings.loader.title} style={{ fontSize: '1.2rem' }} />
                </div>
                <div>
                    <Spinner animation="border" />
                </div>
            </div>
        </Modal>
    );
};
