

type Props = {
    color? : string
    px? : string
    rotation? : string
    height? : string
}
export const ArrowFilterIcon = ({color = 'black', px = '3', rotation = '315', height = '12'} : Props) => {


    const style = {
        display: 'block',
        borderTop: px+'px solid '+color,
        borderRight: px+'px solid '+color,
        transform: 'rotate('+rotation+'deg)',
        width: height+'px',
        height: height+'px',
        borderRadius: '3px',
        margin: '0 auto',
        top: '0',
        position: 'relative' as 'relative'
    }


    return (
        <span style={style}></span>
    );
};

