import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { cssCursor } from './../../config/cssConfig';
import { DisplayColIcon } from './../../assets/icons/DisplayColIcon';
import { accentColor } from './../../ui/styles/GlobalStyle';
import { strings } from './../../res/i18n/strings';
import { UILabel } from '../../ui/UILabel';

type Props = {
    id: number;
    displays: number[];
    changeDisplay: (e: number) => void;
};

export const Display = ({ id, displays, changeDisplay }: Props) => {
    const handleChange = (elementId: number) => {
        changeDisplay(elementId);
    };

    return (
        <div className="Display">
            <Row>
                <Col
                    lg={6}
                    xs={6}
                    style={{ textAlign: 'left', paddingLeft: '1.5em', paddingTop: '0em', fontWeight: 'bold' }}
                >
                    <UILabel text={`${strings.menu.display} : `.toUpperCase()} />
                </Col>
                {displays.map(element => {
                    return (
                        <Col lg={1} xs={2} key={element} onClick={() => handleChange(element)} style={{ ...cssCursor, marginRight: '1em' }}>
                            <DisplayColIcon color={element === id ? accentColor : 'grey'} displayCol={element} />
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};
