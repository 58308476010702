import { booxiMerchantKey } from '../../config/booxiApiConfig';
import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import moment from 'moment';
import { formatISOString } from '../dates/dateFormatter';
import { ServiceAvailability, Availability } from './ServiceAvailability';

export interface ServiceIdAvailabilityRepositorySpec {
    getAvailability(merchantKeys: string, serviceId: number | null, date: string): any;
    displayServiceAvailableLimit(
        listEventGrouped: ServiceAvailability[],
        startDisplayIndex: number,
        limit: number
    ): ServiceAvailability[];
}
export class ServiceIdAvailabilityRepository implements ServiceIdAvailabilityRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getAvailability(merchantKeys: string, serviceId: number | null, date: string) {
        const params: Map<string, string> = new Map<string, string>();
        const nextDate: moment.Moment = moment(date).add(1, 'month');
        let concatItems: ServiceAvailability[] = [];

        params.set('from', formatISOString(date));
        params.set('serviceId', '' + serviceId);
        params.set('to', formatISOString(nextDate));

        let { data } = await Promise.resolve(
            this.datasource.remote.getData(
                `/service/${serviceId}/availability`,
                booxiMerchantKey(merchantKeys ?? ''),
                params
            )
        );

        concatItems = data && data.serviceAvailability && concatItems.concat(data.serviceAvailability);
        concatItems = concatItems ? concatItems.filter((ev: ServiceAvailability) => ev.placesLeft) : [];

        return {
            availableDate: concatItems,
            total: concatItems && concatItems.length
        } as Availability;
    }

    displayServiceAvailableLimit(
        listEventGrouped: ServiceAvailability[],
        startDisplayIndex: number,
        limit: number
    ): ServiceAvailability[] {
        return listEventGrouped.slice(startDisplayIndex, limit);
    }
}
